import React from 'react';

export default function NotFoundView(): JSX.Element {
  return (
    <div className="h-full w-full flex flex-col items-center">
      <div className="text-[10rem] mt-8">404</div>
      <div className="text-3xl">Share Not Found</div>
    </div>
  );
}
