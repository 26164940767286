import React from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { ListingItemFileContent } from 'views/ProjectListing';
import { DocumentTextIcon } from '@heroicons/react/24/outline';

const SideBar = (): JSX.Element => {
  const routeData = useRouteLoaderData('project-listing') as ListingItemFileContent;
  const projectName = routeData && routeData.projectName;
  const projectLogoUrl = routeData && routeData.projectLogoUrl;

  return (
    <aside id="default-sidebar" className="fixed top-16 left-0 z-30 w-72 h-screen" aria-label="Sidebar">
      <div className="h-full px-2 py-1 overflow-y-auto bg-dataops-light">
        <ul className="space-y-2 font-medium">
          {projectName !== undefined && (
            <div className="flex items-center shadow-sm border-b border-gray-200 p-1">
              <div className="flex-shrink-0">
                <img
                  className="bg-white p-1 border-[1px] border-gray-200 inline-block h-10 w-10 object-contain max-w-[120px] rounded-full"
                  src={projectLogoUrl}
                />
              </div>
              <h1 className="ml-2">
                <p className="text-l font-large text-gray-700 group-hover:text-gray-900">{projectName}</p>
              </h1>
            </div>
          )}
        </ul>
        <h1 className="ml-1">
          <a className="bg-grey-700 text-grey text-grey-100 hover:text-grey hover:bg-grey-700 group flex gap-x-2 rounded p-2 text-sm leading-6 text-slate-600">
            <DocumentTextIcon
              className="text-grey text-grey-100 text-slate-600 group-hover:text-grey h-6 w-5 shrink-0"
              aria-hidden="true"
            />
            Repository
          </a>
        </h1>
      </div>
    </aside>
  );
};

export default SideBar;
