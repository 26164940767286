import React from 'react';
import { FolderIcon } from '@heroicons/react/24/outline';
import { ListingItem } from 'utils/types';

interface DirectoryContentsProps {
  contents: ListingItem[];
  dirPath: string;
}

const DirectoryContents = (props: DirectoryContentsProps): JSX.Element => {
  return (
    <div className="flex flex-col mt-4">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full">
          <div className="overflow-hidden border border-neutral-200 rounded">
            <table className=" min-w-full text-left text-sm font-light text-surface ">
              <thead className="border-b border-neutral-200 bg-dataops-table-header">
                <tr>
                  <th scope="col" className="px-4 py-[10px]">
                    Name
                  </th>
                  <th scope="col" className="px-4 py-[10px]">
                    Last commit
                  </th>
                  <th scope="col" className="px-4 py-[10px]">
                    Last update
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.contents
                  .filter(function (item) {
                    return item.type === 'directory';
                  })
                  .sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                  })
                  .map((item, index) => (
                    <tr
                      key={index}
                      className="border-b border-neutral-200 transition duration-400 ease-in-out hover:bg-neutral-200 "
                    >
                      <td className="whitespace-nowrap px-4 py-[10px]">
                        <a
                          href={props.dirPath + (props.dirPath.endsWith('/') ? '' : '/') + item.name}
                          className="flex items-center"
                        >
                          <span className="mr-1">
                            <FolderIcon className="h-4 w-4 text-neutral-600" />
                          </span>
                          {item.name}
                        </a>
                      </td>
                      <td className="whitespace-nowrap px-4 py-[10px]">-</td>
                      <td className="whitespace-nowrap px-4 py-[10px]">-</td>
                    </tr>
                  ))}
                {props.contents
                  .filter(function (item) {
                    return item.type === 'file';
                  })
                  .sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                  })
                  .map((item, index) => (
                    <tr
                      key={index}
                      className="border-b border-neutral-200 transition duration-400 ease-in-out hover:bg-neutral-200 "
                    >
                      <td className="whitespace-nowrap px-4 py-[10px]">
                        {' '}
                        <a
                          href={props.dirPath + (props.dirPath.endsWith('/') ? '' : '/') + item.name}
                          className="flex items-center"
                        >
                          {item.name}
                        </a>
                      </td>
                      <td className="whitespace-nowrap px-4 py-[10px]">-</td>
                      <td className="whitespace-nowrap px-4 py-[10px]">-</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectoryContents;
