import React from 'react';
import { PathItem } from 'utils/types';

interface ProjectBreadcrumbProps {
  ancestors: PathItem[];
}

const ProjectBreadcrumb = (props: ProjectBreadcrumbProps): JSX.Element => {
  return (
    <div className="w-full mb-4 text-sm">
      <ol className="list-reset flex">
        {props.ancestors.map((ancestor, index) => (
          <span key={index} className="flex">
            <li>
              <a href={ancestor.path} className="hover:underline">
                {ancestor.name}
              </a>
              {ancestor.type === 'directory' && <span className="mx-2">/</span>}
            </li>
          </span>
        ))}
      </ol>
    </div>
  );
};

export default ProjectBreadcrumb;
