import React from 'react';
import ProjectBreadcrumb from 'components/ProjectBreadcrumb';
import { useLoaderData } from 'react-router-dom';
import { ListingItem, PathItem } from 'utils/types';
import DirectoryContents from 'components/DirectoryContents';
import FileContents from 'components/FileContents';

export interface ListingItemFileContent {
  ancestors: PathItem[];
  projectName: string;
  projectLogoUrl: string;
  contents: ListingItem[];
  fileContents: string | undefined;
  readmePresent: boolean;
}

export default function ProjectListing(): JSX.Element {
  const { ancestors, contents, fileContents, readmePresent } = useLoaderData() as ListingItemFileContent;

  const currentItem = ancestors[ancestors.length - 1];

  return (
    <div className="container mt-4 p-2">
      {/* Temporily this top section with the logo and share button is commented out */}
      {/*<div className="flex mb-6 shadow border-b border-grey-200 p-4 bg-dataops-table-header">
        <>
          <div className="border-b border-neutral-200 transition duration-400 ease-in-out rounded shadow ">
            <img className="inline-block h-12 w-12 rounded" src={projectLogoUrl} />
          </div>
          <h1 className="ml-4 my-2">
            <p className="text-l font-large text-gray-700 group-hover:text-gray-900 h-8 w-5">{projectName}</p>
          </h1>
        </>
        <div className="ml-auto">
          <button
            className="flex items-center min-w-[5rem] h-8 border rounded border-gray-400 px-3 py-2 text-sm hover:bg-neutral-100 hover:shadow-inner"
            onClick={() => {
              console.log('Share button clicked');
            }}
          >
            <span className="mr-1">
              <ShareIcon className="h-4 w-4 text-gray-600" />
            </span>
            Share
          </button>
        </div>
      </div>*/}

      <ProjectBreadcrumb ancestors={ancestors} />

      {readmePresent && <FileContents fileContents={fileContents} fileName="README.md" showRenderToggle={false} />}

      {currentItem.type === 'directory' ? (
        <DirectoryContents contents={contents} dirPath={currentItem.path} />
      ) : (
        <FileContents fileContents={fileContents} fileName={currentItem.name} />
      )}
    </div>
  );
}
