import React, { useEffect, useState } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css';
import Markdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { foundation } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CodeBracketIcon, DocumentIcon } from '@heroicons/react/24/outline';
import remarkGfm from 'remark-gfm';
import 'github-markdown-css/github-markdown-light.css';

interface FileContentsProps {
  fileContents: string | undefined;
  fileName: string;
  showRenderToggle: boolean;
}

const FileContents = (props: FileContentsProps): JSX.Element => {
  const [renderContents, setRenderContents] = useState(false);

  useEffect(() => {
    if (props.fileName.endsWith('.md')) {
      setRenderContents(true);
    }
  }, []);

  console.log('FileContents props:', props);

  let language = 'plaintext';
  if (props.fileName.includes('.') && !props.fileName.startsWith('.')) {
    const extension = props.fileName.split('.').pop() || 'plaintext';
    const hljsLanguage = hljs.getLanguage(extension);
    if (hljsLanguage) {
      language = hljsLanguage.name?.toLowerCase() || 'plaintext';
      console.log('Highlight.js language:', language);
    }
  }

  if (props.fileContents === undefined) {
    return <div className="">No file contents found</div>;
  }

  return (
    <div className="text-code/[19px] bg-white">
      <div className="flex text-md font-bold text-gray-900 bg-dataops-table-header border-t border-x border-neutral-200 rounded-t items-center px-4 py-[10px] text-sm">
        <span className="">{props.fileName}</span>
        {props.fileName.endsWith('.md') && props.showRenderToggle && (
          <div className="flex ml-auto">
            <button
              className={
                'items-center h-8 rounded-l border-gray-400 px-2 py-1 text-sm hover:bg-neutral-100 hover:shadow-inner ' +
                (renderContents ? 'border' : 'border-2')
              }
              onClick={() => {
                setRenderContents(false);
              }}
            >
              <span className="">
                <CodeBracketIcon className="h-4 w-4 text-gray-600" />
              </span>
            </button>
            <button
              className={
                'items-center h-8 rounded-r border-gray-400 px-2 py-1 text-sm hover:bg-neutral-100 hover:shadow-inner ' +
                (renderContents ? 'border-2' : 'border')
              }
              onClick={() => {
                setRenderContents(true);
              }}
            >
              <span className="">
                <DocumentIcon className="h-4 w-4 text-gray-600" />
              </span>
            </button>
          </div>
        )}
      </div>
      <div className="border border-neutral-200">
        {!renderContents && (
          <SyntaxHighlighter
            language={language}
            style={foundation}
            showLineNumbers={true}
            customStyle={{
              backgroundColor: '#fff',
              padding: '0px',
              // border: '1px solid rgb(212 212 212)', // border-neutral-300
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
            }}
            lineNumberStyle={{
              color: 'rgb(137, 136, 141)',
              paddingRight: '10px',
              marginRight: '10px',
              minWidth: '96px',
              borderRight: '1px solid rgb(212 212 212)', // border-neutral-300
              backgroundColor: '#fbfafd',
            }}
          >
            {props.fileContents}
          </SyntaxHighlighter>
        )}
        {renderContents && (
          // Tailwind removes all of the default browser styling from paragraphs, headings, lists and more
          // the "prose" class along with the @tailwindcss/typeography plugin adds sensible styles back.
          <div className="markdown-body prose">
            {renderContents && <Markdown remarkPlugins={[remarkGfm]}>{props.fileContents}</Markdown>}
          </div>
        )}
      </div>
    </div>
  );
};

FileContents.defaultProps = {
  showRenderToggle: true,
};

export default FileContents;
