import React from 'react';
import { NavLink } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import AppSwitcher from './AppSwitcher';

const AppBar = (): JSX.Element => {
  return (
    <Disclosure as="nav" className="bg-dataops-dark h-16 w-screen fixed top-0 z-40">
      <div className="w-full h-full pl-[0.8rem] pr-2 sm:pr-4 lg:pr-8">
        <div className="w-full h-full relative flex items-center">
          <div className="flex-grow flex items-center px-2 lg:px-0">
            <AppSwitcher />
            <div className="flex-shrink-0 pl-6">
              <NavLink to="/">
                <img className="h-8 w-auto" src="/static/dataops-logo-with-tagline.svg" alt="DataOps.live" />
              </NavLink>
            </div>
            <div className="ml-6 px-6 border-l border-r border-secondary-text">
              <NavLink to="/">
                <img
                  className="h-[1.6rem] w-[auto]"
                  src="https://www.snowflake.com/wp-content/themes/snowflake/assets/img/logo-white.svg"
                  alt="snowflake logo white"
                />
              </NavLink>
            </div>
            <div className="px-4 text-sm text-gray-100 font-semibold cursor-default">Solution Central</div>
          </div>
        </div>
      </div>
    </Disclosure>
  );
};

export default AppBar;
