import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ProjectListing from 'views/ProjectListing';
import NotFoundView from 'views/NotFoundView';
import { testCiFileContents, testManifest, testMetadata, testReadmeFileContents } from 'testFiles';
import { ListingItem, PathItem } from 'utils/types';

interface MetadataListingItem {
  solutionTemplateName: string;
  customerLogoUrl: string;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        id: 'project-listing',
        path: ':listingId/-/tree/*?',
        element: <ProjectListing />,
        errorElement: <NotFoundView />,
        loader: async ({ params }) => {
          //TODO: get manifest from app state or from public bucket if undefined??

          let manifest: ListingItem[] = [];
          let metadata: MetadataListingItem;

          if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
            console.log('Running on localhost, using local test file');
            manifest = testManifest as ListingItem[];
            metadata = testMetadata as MetadataListingItem;
          } else {
            const manifestFromS3 = await fetch(
              `https://${location.hostname}/shares/v1/${params.listingId}/manifest.json`,
            );
            const metadataFromS3 = await fetch(
              `https://${location.hostname}/shares/v1/${params.listingId}/metadata.json`,
            );
            manifest = await manifestFromS3.json();
            metadata = await metadataFromS3.json();
          }

          let filePath = params['*'] || '';
          // Remove trailing slash if present
          if (filePath.endsWith('/')) {
            filePath = filePath.slice(0, -1);
          }

          console.log(`filePath is: ${filePath}`);

          let projectName = 'Test Project';
          let projectLogoUrl = '';

          let contents: ListingItem[] = manifest;

          projectName = metadata.solutionTemplateName;
          projectLogoUrl = decodeURIComponent(metadata.customerLogoUrl);

          const projectRoot = `/${params.listingId}/-/tree/`;
          const ancestors: PathItem[] = [{ path: projectRoot, type: 'directory', name: projectName }];

          if (filePath) {
            const filePathParts = filePath.split('/').filter((part) => part !== '');
            filePathParts.forEach((part) => {
              let matched = false;
              contents.forEach((item) => {
                if (item.name === part) {
                  contents = item.contents || [];
                  const parentPath = ancestors[ancestors.length - 1].path;
                  const itemPath = parentPath + (parentPath.endsWith('/') ? '' : '/') + item.name;
                  ancestors.push({ path: itemPath, type: item.type, name: item.name });
                  matched = true;
                }
              });
              if (!matched) {
                console.error(`Could not find path part: ${part}`);
                throw new Error(`Could not find path part: ${part}`);
              }
            });
          }

          console.log('Ancestors:', ancestors);

          let fileContents: string | undefined = undefined;
          let readmePresent = false;

          if (ancestors[ancestors.length - 1].type === 'file') {
            if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
              console.log('Running on localhost, using local test file contents');
              if (filePath.endsWith('.md')) {
                fileContents = testReadmeFileContents;
              } else {
                fileContents = testCiFileContents;
              }
            } else {
              const fileFromS3 = await fetch(
                `https://${location.hostname}/shares/v1/${params.listingId}/files/${filePath}`,
              );
              fileContents = await fileFromS3.text();
            }
          } else {
            const readme = contents.filter((item) => item.type === 'file' && item.name === 'README.md');
            if (readme.length > 0) {
              readmePresent = true;
              if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
                console.log('Running on localhost, using local test file contents');
                fileContents = testReadmeFileContents;
              } else {
                let url = `https://${location.hostname}/shares/v1/${params.listingId}/files/README.md`;
                if (filePath !== '') {
                  url = `https://${location.hostname}/shares/v1/${params.listingId}/files/${filePath}/README.md`;
                }
                const fileFromS3 = await fetch(url);
                fileContents = await fileFromS3.text();
              }
            }
          }

          return { ancestors, projectName, projectLogoUrl, contents, fileContents, readmePresent };
        },
      },
      {
        path: '*',
        element: <NotFoundView />,
      },
    ],
  },
]);

const container = document.getElementById('root');

if (container != null) {
  const root = ReactDOM.createRoot(container);

  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
