import React from 'react';

import AppBar from 'components/AppBar';
import { Outlet } from 'react-router-dom';
import SideBar from 'components/SideBar';

export default function App(): JSX.Element {
  return (
    <div className="h-full">
      <div className="w-full fixed top-0 z-40">
        <AppBar />
      </div>
      <SideBar />

      <div className="flex ml-72 h-full flex-col mt-[3.9rem]">
        <div className="flex h-full">
          <main className="min-w-0 flex-1 mx-auto max-w-[1280px] px-4 border-t border-gray-200">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
}
